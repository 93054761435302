import _react2 from "react";
import _requestTimeout2 from "@essentials/request-timeout";
var exports = {};
exports.__esModule = true;
exports.default = exports.useDebounce = exports.useDebounceCallback = void 0;

var _react = _interopRequireDefault(_react2);

var _requestTimeout = _requestTimeout2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

const {
  useCallback,
  useEffect,
  useState,
  useRef
} = _react.default;

const useDebounceCallback = (callback, wait = 100, leading = false) => {
  const timeout = useRef(null); // cleans up pending timeouts when the function changes

  function _ref() {
    if (timeout.current !== null) {
      ;
      (0, _requestTimeout.clearRequestTimeout)(timeout.current);
      timeout.current = null;
    }
  }

  useEffect(() => _ref, [callback, wait]);
  return useCallback(function (...args) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (timeout.current === null && leading) callback.apply(self, args);else if (timeout.current !== null) (0, _requestTimeout.clearRequestTimeout)(timeout.current);
    timeout.current = (0, _requestTimeout.requestTimeout)(() => {
      timeout.current = null;
      !leading && callback.apply(self, args);
    }, wait);
  }, [callback, wait]);
};

exports.useDebounceCallback = useDebounceCallback;

const useDebounce = (initialState, wait, leading) => {
  const _ref_0 = useState(initialState);

  const setState = _ref_0[1];
  const state = _ref_0[0];
  return [state, useDebounceCallback(setState, wait, leading)];
};

exports.useDebounce = useDebounce;
var _default = useDebounce;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;
const _useDebounce = exports.useDebounce,
      _useDebounceCallback = exports.useDebounceCallback;
export { _useDebounce as useDebounce, _useDebounceCallback as useDebounceCallback };